import React, { useEffect, useContext, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import {
  PassengerTypes,
  PRICE_FREEZE_REVIEW_DETAILS,
  PriceFreezeEntryEnum,
  EntryButtonEnum,
  PreviousFlightEnum,
  CallState,
} from "redmond";
import { RouteComponentProps } from "react-router";

import { PriceFreezePurchaseConnectorProps } from "./container";
import {
  DesktopFlightFreezeWorkflow,
  MobileFlightFreezeWorkflow,
} from "../index";
import { ClientContext } from "../../../../App";
import {
  BookingErrorModal,
  BookingInProgressModal,
} from "../../../book/components";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  PORTAL_TITLE,
  PRICE_FREEZE_PURCHASE,
} from "../../../../lang/textConstants";
import { ISelectedTrip } from "../../../shop/reducer";
import {
  AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE,
  UC_AVAILABLE,
  UC_DEFAULT_VARIANTS,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { PATH_FREEZE_CONFIRMATION } from "../../../../utils/urlPaths";

export interface IPriceFreezePurchaseProps
  extends RouteComponentProps,
    PriceFreezePurchaseConnectorProps {}

export const PriceFreezePurchase = (props: IPriceFreezePurchaseProps) => {
  const {
    offer,
    history,
    searchCounts,
    selectedTrip,
    passengerCounts,
    confirmationEmail,
    isContactStepComplete,
    priceFreezeOfferCallState,
    priceFreezeReviewDetailsProperties,
    setUpFlightFreezeParamsCallState,
    generateCustomPriceFreezeOfferCallState,
    resetPaymentCardSelectedAccounts,
    setUpFlightFreezeParams,
    setPassengerCounts,
    setContactInfo,
    scheduleQuote,
    fetchPriceFreezeFareQuote,
    cancelFetchPriceFreezeFareQuote,
  } = props;
  const clientContext = useContext(ClientContext);
  const { sessionInfo } = clientContext;
  const { matchesMobile, matchesDesktop } = useDeviceTypes();

  const { experiments } = useExperiments();
  const isInPurchaseOnQuoteOnlyXp = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        experiments,
        AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE,
        UC_DEFAULT_VARIANTS
      ) === UC_AVAILABLE,
    [experiments]
  );

  useEffect(() => {
    document.title = PRICE_FREEZE_PURCHASE(false);

    setContactInfo(sessionInfo?.userInfo?.email || "", "");
    setPassengerCounts({
      adultsCount: searchCounts[PassengerTypes.Adult] ?? 0,
      childrenCount: searchCounts[PassengerTypes.Child] ?? 0,
      infantsInSeatCount: searchCounts[PassengerTypes.InfantInSeat] ?? 0,
      infantsOnLapCount: searchCounts[PassengerTypes.InfantInLap] ?? 0,
    });
    setUpFlightFreezeParams({ history, isMobile: matchesMobile });

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, [history]);

  // Derived states
  const tripId = selectedTrip.tripId;
  const fareId =
    (selectedTrip as ISelectedTrip).returnFareId ??
    (selectedTrip as ISelectedTrip).outgoingFareId;

  const { adultsCount, childrenCount, infantsInSeatCount, infantsOnLapCount } =
    passengerCounts;

  useEffect(() => {
    if (tripId && fareId) {
      fetchPriceFreezeFareQuote({
        passengerCountByType: {
          [PassengerTypes.Adult]: adultsCount,
          [PassengerTypes.Child]: childrenCount,
          [PassengerTypes.InfantInSeat]: infantsInSeatCount,
          [PassengerTypes.InfantInLap]: infantsOnLapCount,
        },
        tripId,
        fareId,
        isInPurchaseOnQuoteOnlyXp,
      });
    }
    return () => {
      console.log("@@@history.location.pathname: ", history.location.pathname);
      // If a customer goes to PATH_FREEZE_CONFIRMATION, we don't want to cancel
      if (history.location.pathname !== PATH_FREEZE_CONFIRMATION) {
        cancelFetchPriceFreezeFareQuote();
      }
    };
  }, [
    history.location.pathname,
    tripId,
    fareId,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  ]);

  useEffect(() => {
    if (
      !!offer?.id &&
      confirmationEmail &&
      isContactStepComplete &&
      // note: when setUpFlightFreezeParamsSaga has completed, the scheduleQuote request selector will be properly built
      setUpFlightFreezeParamsCallState === CallState.Success
    ) {
      scheduleQuote({
        pollQuoteOnly: true,
        isPriceFreezePurchase: true,
      });
    }
  }, [
    // note: when any of offerId, confirmationEmail or passengerCounts changes, it will cause scheduleQuote to be fired
    offer?.id,
    confirmationEmail,
    isContactStepComplete,
    passengerCounts,
    setUpFlightFreezeParamsCallState,
  ]);

  useEffect(() => {
    // note: whenever a successful generate offer request is made (PF duration is changed), it resets the selected payment method
    if (generateCustomPriceFreezeOfferCallState === CallState.Success) {
      resetPaymentCardSelectedAccounts();
    }
  }, [generateCustomPriceFreezeOfferCallState]);

  // tracking event effect for PF purchase
  useEffect(() => {
    // note: the PRICE_FREEZE_REVIEW_DETAILS event is fired on the PF purchase screen only
    if (priceFreezeOfferCallState === CallState.Success) {
      const { entry, entryButton, prevFlight }: any =
        history.location.state || {};
      trackEvent({
        eventName: PRICE_FREEZE_REVIEW_DETAILS,
        properties: {
          price_freeze_entry: entry ?? PriceFreezeEntryEnum.TRIP_SUMMARY,
          entry_button: entryButton ?? EntryButtonEnum.INFO_MODAL,
          previous_flight: prevFlight ?? PreviousFlightEnum.CHOSEN_FLIGHT,
          ...priceFreezeReviewDetailsProperties,
        },
      });
    }
  }, [history, priceFreezeOfferCallState, priceFreezeReviewDetailsProperties]);

  return (
    <Box className="price-freeze-purchase-root">
      <Box className="price-freeze-purchase-container">
        {matchesDesktop && (
          <DesktopFlightFreezeWorkflow
            useLockPriceLanguage={false}
            disableChangeFlight={false}
          />
        )}
        {matchesMobile && (
          <MobileFlightFreezeWorkflow useLockPriceLanguage={false} />
        )}
        <BookingErrorModal />
        <BookingInProgressModal
          useHighZIndex={true}
          hidePriceQuoteInProgress={true}
        />
      </Box>
    </Box>
  );
};
